import { ReactComponent as FacebookSvg } from '../media/images/icons/facebook.svg';
import { ReactComponent as LinkedinSvg } from '../media/images/icons/linkedin.svg';
import { ReactComponent as InstagramSvg } from '../media/images/icons/instagram.svg';

function Footer() {
    return (
        <div className="bg-primary text-white py-10">
            <div className="container mx-auto w-3/5 grid grid-cols-1 gap-y-4 md:grid-cols-4">
                <div className="flex justify-center">
                    <a href="/">
                        <img className="w-24" src={require("../media/images/icons/logo_white.png")} alt="FOTI Immobilier Sàrl" />
                    </a>
                </div>
                <div className="flex justify-center font-bold">
                    <div>
                        <p>FOTI Immobilier Sàrl</p>
                    </div>
                </div>
                <div className="flex justify-center text-sm">
                    <div>
                        <p>Rue de Venise 8</p>
                        <p>1870 Monthey</p>
                        <br />
                        <p>Rue du Clos 12</p>
                        <p>1800 Vevey</p>
                    </div>
                </div>
                <div className="flex justify-center text-sm">
                    <div>
                        <p><a className="font-bold hover:underline" href="mailto:info@foti-immo.ch">info@foti-immo.ch</a></p>
                        <p>Tél. <a className="font-bold hover:underline" href="tel:+41245658008">+41 24 565 8008</a></p>
                        <br />
                        <p>Tél. <a className="font-bold hover:underline" href="tel:+41218110911">+41 21 811 0911</a></p>
                    </div>
                </div>
            </div>
            <div className="flex justify-center gap-x-4 mt-4">
                <div className="rounded-full fill-primary group bg-white w-8 h-8 p-1 hover:bg-primary-light hover:fill-white">
                    <a href="https://www.facebook.com/fotimmo/">
                        <FacebookSvg />
                    </a>
                </div>
                <div className="rounded-full fill-primary group bg-white w-8 h-8 p-2 hover:bg-primary-light hover:fill-white visited:fill-primary">
                    <a href="https://www.linkedin.com/company/foti-immobilier/about/">
                        <LinkedinSvg />
                    </a>
                </div>
                <div className="rounded-full fill-primary group bg-white w-8 h-8 p-1 hover:bg-primary-light hover:fill-white">
                    <a href="https://www.instagram.com/foti_immo/">
                        <InstagramSvg />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
